import React, {Component} from 'react';

import {RadialChart, LabelSeries, DiscreteColorLegend} from 'react-vis';


const ITEMS = [
    'IDO/IEO/PRESALE',
    'TOKEN STAKING POOL',
    'MARKETING & COMMUNITY',
    'LIQUIDITY',
    'TEAM',
    '$AAA WSB FARMING',
];





const LABEL_STYLE = {
    fontSize: '8px',
    textAnchor: 'middle',
    color: '#FAFAFA'
};

export default class Donut extends Component {

    render() {
        return (
            <RadialChart
                // className={'donut-chart-example'}
                innerRadius={140}
                radius={160}
                getAngle={d => d.theta}

                getLabel={d => d.country}
                data={[
                    {theta: 48, country: '48%'},
                    {theta: 18, country: '18%'},
                    {theta: 14, country: '14% '},
                    {theta: 10, country: '10%'},
                    {theta: 5, country: '5%'},
                    {theta: 5, country: '5%'}
                ]}

                // data={[
                //     {theta: 48, country: 'IDO/IEO/PRESALE'},
                //     {theta: 18, country: 'TOKEN STAKING POOL'},
                //     {theta: 14, country: 'MARKETING & COMMUNITY'},
                //     {theta: 10, country: 'LIQUIDITY'},
                //     {theta: 5, country: 'TEAM'},
                //     {theta: 5, country: '$AAA WSB\n FARMING'}
                // ]}
                // onValueMouseOver={v => this.setState({value: v})}
                // onSeriesMouseOut={v => this.setState({value: false})}
                width={500} // 300
                height={500} // 300
                padAngle={0.04}
                labelsRadiusMultiplier={1.159}
                labelsStyle={{fontSize: 12, fill: '#fafafa',font: 'Neue Helvetica'}}
                showLabels
            >
                <div style={{marginTop: '-300px', marginLeft:'140px'}}>
                    <DiscreteColorLegend height={600} width={100} items={ITEMS} />
                </div>

                {/*<LabelSeries*/}
                {/*    data={[*/}
                {/*        {x: 0.1, y: 0.5, label: 'IDO/IEO/PRESALE', style: {fontSize: 8, fill: '#fafafa'}},*/}
                {/*        {x: 0.1, y: 0.3, label: 'TOKEN STAKING POOL', style: {fontSize: 8, fill: '#fafafa'}},*/}
                {/*        {x: 0.1, y: 0, label: 'MARKETING & COMMUNITY 🟠', style: {fontSize: 8, fill: 'white'}},*/}
                {/*        {x: 0.1, y: -0.6, label: 'LIQUIDITY 🟡', style: {fontSize: 8, fill: '#fafafa'}},*/}
                {/*        {x: 0.1, y: -0.4, label: 'TEAM 🔵', style: {fontSize: 8, fill: '#fafafa'}},*/}
                {/*        {x: 0.1, y: -0.2, label: '$AAA WSB FARMING', style: {fontSize: 8, fill: '#fafafa'}},*/}
                {/*                            ]}*/}
                {/*/>*/}

            </RadialChart>

        );
    }
}